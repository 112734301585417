import Common from "./_common";
import slick from "slick-carousel";
import "slick-carousel/slick/slick.css";

$(function () {
  var mainSlider = ".front-mv__slide-list";
  var thumbnailSlider = ".front-mv__slide-thumb-list";

  $(mainSlider).slick({
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 400,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: thumbnailSlider,
  });
  $(thumbnailSlider).slick({
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    speed: 400,
    arrows: false,
    focusOnSelect: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    asNavFor: mainSlider,
  });
  $(thumbnailSlider + ".slick-slide").on("click", function () {
    var index = $(this).attr("data-slick-index");
    $(thumbnailSlider).slick("slickGoTo", index, false);
  });
  var slideCount = 0;
  $(".front-mv__slide-thumb-list .slick-slide").each(function () {
    slideCount++;
  });
  if (slideCount < 6) {
    $(".front-mv__slide-thumb-list .slick-track").addClass("lock");
  }
});

$(".front-course__card").on("click", function () {
  if (window.matchMedia("(max-width: 1080px)").matches) {
    $(this).addClass("js-open");
    $(".front-course__close-btn").addClass("js-open");
    $("html,body").addClass("js-open");
  }
});

$(".front-course__close-btn").on("click", function () {
  $(".front-course__card").removeClass("js-open");
  $(this).removeClass("js-open");
  $("html,body").removeClass("js-open");
});

$(window).on("resize", function () {
  $(".front-course__card").removeClass("js-open");
  $(".front-course__close-btn").removeClass("js-open");
  $("html,body").removeClass("js-open");
});

var timer;
$(".front-course__card").hover(
  function () {
    if (window.matchMedia("(min-width: 1081px)").matches) {
      var subNav = $(this).find(".front-course__card-inner");
      subNav.stop(true, true).slideDown("fast");
    }
  },
  function () {
    if (window.matchMedia("(min-width: 1081px)").matches) {
      var subNav = $(this).find(".front-course__card-inner");
      timer = setTimeout(function () {
        subNav.stop(true, true).slideUp("fast");
      }, 100);
    }
  }
);

$(window).on("resize", function () {
  if (window.matchMedia("(max-width: 1080px)").matches) {
    $(".front-course__card-inner").removeAttr("style");
  }
});

$("#blog-nav .blog-nav__item").on("click", function () {
  if ($(this).not("active")) {
    // タブメニュー
    $(this).addClass("active").siblings(".blog-nav__item").removeClass("active");
    // タブの中身
    var index = $("#blog-nav .blog-nav__item").index(this);
    $("#blog-box .blog-box__inner").eq(index).addClass("active").siblings(".blog-box__inner").removeClass("active");
  }
});

var hash = location.hash;
hash = (hash.match(/^#tag\d+$/) || [])[0];
if ($(hash).length) {
  var tagname = hash.slice(1);
} else {
  var tagname = "blog-nav1";
}
$("#blog-nav .blog-nav__item").removeClass("active");
var tagno = $("div#blog-nav div#" + tagname).index();
$("#blog-box .blog-box__inner").eq(tagno).addClass("active").siblings(".blog-box__inner").removeClass("active");
$("div#blog-nav .blog-nav__item").eq(tagno).addClass("active");

class Common {
  constructor() {
    const ua = navigator.userAgent.toLowerCase();
    const isMobile = /iphone/.test(ua) || /android(.+)?mobile/.test(ua);
    if (!isMobile) {
      $('a[href^="tel:"]').on("click", function (e) {
        e.preventDefault();
      });
    }

    const $win = $(window);
    const $bread = $(".breadcrumb-wrap");
    const $nav = $(".gnav");
    const navPos = $nav.offset().top;
    const fixedClass = "is-fixed";

    $(function () {
      $('a[href*="#"]').click(function () {
        var w = $(window).width();
        var x = 769;
        if (w <= x) {
          var headerHight = 60;
        } else {
          var headerHight = 80;
        }
        var str = $(this).attr("href");

        var cut_str = "#";
        var index = str.indexOf(cut_str);
        var href = str.slice(index);

        if (href == "#") {
          var target = $("body");
          var position = target.offset().top - headerHight;
          $("html, body").animate({ scrollTop: position }, 500, "swing");
          return false;
        } else {
          // ページ内にリンク先がある
          if ($(href).length) {
            var target = href;
            var position = $(target).offset().top - headerHight;
            $("html, body").animate({ scrollTop: position }, 500, "swing");
            return false;
          }
        }
      });
    });

    $(window).on("load", function () {
      var w = $(window).width();
      var x = 769;
      if (w <= x) {
        var headerHight = 60;
      } else {
        var headerHight = 80;
      }

      if (document.URL.match("#")) {
        var str = location.href;
        var cut_str = "#";
        var index = str.indexOf(cut_str);
        var href = str.slice(index);
        var target = href;
        var position = $(target).offset().top - headerHight;
        $("html, body").scrollTop(position);
        return false;
      }
    });

    $win.on("load scroll", function () {
      const value = $(this).scrollTop();
      if (value > navPos) {
        $nav.addClass(fixedClass);
        $bread.addClass(fixedClass);
      } else {
        $nav.removeClass(fixedClass);
        $bread.removeClass(fixedClass);
      }
    });

    $(".btn-menu").on("click", function () {
      $(this).toggleClass("js-open");
      $(".gnav").toggleClass("js-open");
      $("html,body").toggleClass("js-open");
    });

    $(window).on("resize", function () {
      $(".btn-menu").removeClass("js-open");
      $(".gnav").removeClass("js-open");
      $("html,body").removeClass("js-open");
      $(".gnav__text").removeClass("active");
      $(".gnav__text").next("ul").removeAttr("style");
    });

    var timer;
    $(".gnav__item.has-child").hover(
      function () {
        if (window.matchMedia("(min-width: 1081px)").matches) {
          var subNav = $(this).find(".gnav-sub__inner");
          if ($(".gnav__list").hasClass("is-open")) {
            $(".gnav-sub__inner").hide();
            $(".gnav__item").removeClass("is-open");
            subNav.show();
            subNav.find(".gnav-sub__list").hide().stop().fadeIn();
            subNav.parent(".gnav__item").addClass("is-open");
            if (timer) clearTimeout(timer);
          } else {
            $(".gnav__list").addClass("is-open");
            subNav.stop(true, true).slideDown("fast");
            subNav.parent(".gnav__item").addClass("is-open");
          }
        }
      },
      function () {
        if (window.matchMedia("(min-width: 1081px)").matches) {
          var subNav = $(this).find(".gnav-sub__inner");
          timer = setTimeout(function () {
            $(".gnav__list").removeClass("is-open");
            subNav.stop(true, true).slideUp("fast");
            subNav.parent(".gnav__item").removeClass("is-open");
          }, 300);
        }
      }
    );

    $(window).on("resize", function () {
      if (window.matchMedia("(max-width: 1080px)").matches) {
        $(".gnav-sub__inner").removeAttr("style");
      }
    });

    var showFlag = false;
    var topBtn = $(".pagetop");
    topBtn.css("bottom", "-100px");
    var showFlag = false;
    $(window).scroll(function () {
      if (window.matchMedia("(min-width: 1081px)").matches) {
        if ($(this).scrollTop() > 100) {
          if (showFlag == false) {
            showFlag = true;
            topBtn.stop().animate({ bottom: "20px" }, 200);
          }
        } else {
          if (showFlag) {
            showFlag = false;
            topBtn.stop().animate({ bottom: "-100px" }, 200);
          }
        }
      } else {
        topBtn.removeAttr("style");
      }
    });
  }
}

export default new Common();
